import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="common-top-spacing">
                <section className="privacy-policy-outer">
                    <Container>
                        <div className="privacy-policy-inner">
                            <h1>Privacy Policy</h1>
                            <h5>Introduction</h5>
                            <p>Welcome to Helpr, an AI-powered conversational platform developed by OpenAI. Your privacy is important to us, and this Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services.</p>
                            <h5>Information We Collect  </h5>
                            <h6>1. User-Provided Information:</h6>
                            <ul>
                                <li><b>Account Information:</b> If you create an account, we may collect personal information such as your name, email address, and other contact details.</li>
                                <li><b>Communications:</b> When you interact with Helpr, we collect the content of the conversations to improve our services and provide accurate responses.</li>
                            </ul>
                            <h6>2. Automatically Collected Information:</h6>
                            <ul>
                            <li><b>Usage Data</b> We collect information about your interactions with Helpr, including the date and time of your visits, the pages viewed, and other interaction information.</li>
                            <li><b>Device Information:</b> We may collect information about the device you use to access our services, including the hardware model, operating system, and IP address.</li>
                            </ul>
                           
                            <h6>3. Cookies and Similar Technologies:</h6>
                            <ul>
                                <li>We use cookies and similar tracking technologies to enhance your experience, understand user activity, and improve our services.</li>
                            </ul>
                            <h5>How We Use Your Information</h5>
                            <h6>1. Service Delivery:</h6>
                            <ul>
                                <li>To provide, maintain, and improve Helpr’s services.</li>
                                <li>To personalize your experience and respond to your inquiries.</li>
                            </ul>
                            <h6>2. Analytics and Improvements:</h6>
                            <ul>
                                <li>To analyze usage trends and preferences to improve the functionality and user experience of Helpr.
                                </li>
                            </ul>
                            <h6>3. Communication:</h6>
                            <ul>
                                <li>To send you updates, security alerts, and administrative messages.</li>
                                <li>To respond to your comments, questions, and requests.</li>
                            </ul>
                            <h5>How We Share Your Information</h5>
                            <h6>1. Third-Party Service Providers:</h6>
                            <ul>
                                <li>We may share your information with third-party service providers who assist us in operating our services, conducting our business, or serving our users.</li>
                            </ul>
                            <h6>2. Legal Requirements:</h6>
                            <ul>
                                <li>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                            </ul>
                            <h6>3. Business Transfers:</h6>
                            <ul>
                                <li>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                            </ul>
                            <h6>Data Security</h6>
                            <p>We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.</p>
                            <h5>Your Choices</h5>
                            <h6>1. Access and Correction:</h6>
                            <ul>
                                <li>You may access, update, or correct your personal information by contacting us.</li>
                            </ul>
                            <h6>2. Opt-Out:</h6>
                            <ul>
                                <li>You can opt-out of receiving promotional communications from us by following the instructions in those communications or by contacting us directly.</li>
                            </ul>
                            <h6>3. Data Deletion:</h6>
                            <ul>
                                <li>You may request the deletion of your personal information by contacting us. We will take reasonable steps to delete your information, except where we are required to retain it for legal reasons.</li>
                            </ul>
                            <h6>Children's Privacy</h6>
                            <p>Helpr is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
}
export default PrivacyPolicy;