import React, { useState, useEffect, useRef, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { socketUrl } from '../config/config';
import { apiService } from '../service/api.service';
import ImageGeneration from './ImageGeneration';
import UploadPdf from './UploadPdf';
import LinkTool from './LinkTool';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import ImageFullScreen from '../common/ImageFullScreen';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const Chat = () => {

    const DUMMY_QUESTIONS = ["Generate a sales pitch for a new product.", "Help me to find a perfect laptop for my office setup.", "Generate a sales pitch for my new car."];
    const DUMMY_IMG_QUESTIONS = ["Create an image of a fantasy dragon perched majestically on a cliff.", "Imagine a vibrant cyberpunk street bustling with activity at night.", "Design a tranquil Japanese Zen garden setting."];

    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const globalContext = useContext(GlobalContext);
    const [currentSocket, setCurrentSocket] = useState(null);
    const [question, setQuestion] = useState("");
    const [thread, setThread] = useState(null);
    const [messages, setMessages] = useState([]);
    const [pdfFiles, setPdfFiles] = useState([]);
    const [link, setLink] = useState("");
    const [queryParams] = useSearchParams();
    const [fullImageScreen, setFullImageScreen] = useState(null);
    const [shouldScrollBottom, setShouldScrollBottom] = useState(true);

    const { sendMessage, lastMessage, readyState } = useWebSocket(currentSocket);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        if (queryParams.get('chatType') === "text" || queryParams.get('chatType') === "image") {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
        if (queryParams.get('thread')) {
            getThreadMessages();
            setThread(queryParams.get('thread'));
        } else {
            setThread(null);
            setMessages([]);
            if (!globalContext.loggedIn) {
                getMachineIp();
            } else {
                setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            }
        }
    }, [queryParams, location.search]);

    function formatContent(content) {
        // Replace ** with <strong> for bold text
        content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Replace #, ##, ###, etc. at the start of a line with <h1>, <h2>, <h3>, etc. and </h1>, </h2>, </h3> at the end of the line
        content = content.replace(/^(#{1,6})\s*(.*?)$/gm, function(match, p1, p2) {
            const level = p1.length; // The number of # characters determines the heading level
            return `<h${level}>${p2}</h${level}>`;
        });

        // Replace bullet lists (-, *) with <ul> and <li> tags
        content = content.replace(/^-\s*(.*)$/gm, '<ul><li>$1</li></ul>');

        // Replace \n with <br> for new lines
        content = content.replace(/\n/g, '<br/>');
        return content;
    }

    useEffect(() => {
        if (shouldScrollBottom && messagesEndRef.current && messages.length > 0) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        // handleSuggestionClick("");
    }, [messages, shouldScrollBottom]);

    const handleScroll = () => {
        // Check if user is scrolled to the bottom
        const isScrolledToBottom = messagesEndRef.current.getBoundingClientRect().bottom <= window.innerHeight;
        // Only auto-scroll if already at the bottom
        setShouldScrollBottom(isScrolledToBottom);
    };


    useEffect(() => {
        if (lastMessage !== null) {
            handleScroll();
            const data = JSON.parse(lastMessage.data);
            console.log("last message data: ", data);
            setMessages(prevMessages => {
                const newMessages = [...prevMessages];
                if (data.message) {
                    setThread(data.thread);
                    newMessages[newMessages.length - 1].response = formatContent(data.message);
                    return newMessages;
                } else if (data.error) {
                    if (data.error.includes("Limit reached")) {
                        globalContext.setLock(true);
                        newMessages[newMessages.length - 1].response = "";
                        return newMessages;;
                    } else {
                        newMessages[newMessages.length - 1].response = data.error;
                        return newMessages;
                    }
                } else {
                    return prevMessages;
                }
            });
            globalContext.setGenerating(data.stream ? true : false);
            if (data?.thread && data?.thread !== queryParams.get('thread') && !data?.stream) {
                navigate(`/?chatType=${queryParams.get('chatType')}&thread=${data.thread}`, { replace: true });
            }
            globalContext.setThreads(prevThreads => {
                if (data.thread_title) {
                    const existingThreadIndex = prevThreads?.findIndex(thread => thread.id === data.thread);
                    if (existingThreadIndex !== -1) {
                        const updatedThreads = [...prevThreads];
                        updatedThreads[existingThreadIndex].title = data.thread_title;
                        return updatedThreads;
                    } else {
                        return [{ id: data.thread, title: data.thread_title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                    }
                }
                return prevThreads;
            })
        }
    }, [lastMessage]);

    useEffect(() => {
        if (!queryParams.get('chatType') || !['text', 'image', 'pdf', 'link'].includes(queryParams.get('chatType'))) {
            navigate("/?chatType=text");
        }
        setPdfFiles([]);
    }, [location.search]);

    useEffect(() => {
        setMessages([]);
        globalContext.setLock(false);
        globalContext.setGenerating(false);
        if (!globalContext.loggedIn) {
            getMachineIp();
        } else {
            setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            if (globalContext.showPlanScreen && !globalContext.userInfo?.subscription_id) {
                globalContext.setPlanModal(true);
            }
            globalContext.setShowPlanScreen(false);
        }
    }, [globalContext.loggedIn]);

    const getMachineIp = () => {
        apiService.getMyIp().then((res) => {
            if (res.status === 200) {
                setCurrentSocket(`${socketUrl}guest/chat/${res.data.ip}/`)
            }
        })
    }

    const handleSuggestionClick = (item) => {
        setQuestion(item);
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    const connectUserSocket = () => {
        const chatType = queryParams.get('chatType');
        const thread = queryParams.get('thread');
        if (globalContext.loggedIn) {
            if (chatType === 'text') {
                setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            } else if (chatType === 'pdf') {
                setCurrentSocket(`${socketUrl}pdf/${thread}/`)
            } else if (chatType === 'link') {
                setCurrentSocket(`${socketUrl}link/${thread}/`)
            } else {
                setCurrentSocket(null);
            }
        } else {
            getMachineIp();
        }
    }

    const getThreadMessages = () => {
        if (globalContext.loggedIn) {
            // globalContext.setLoader(true);
            apiService.getUserThreadMessage(queryParams.get('thread')).then((res) => {
                if (res.status === 200) {
                    const formattedMessages = res.data.data.map(message => {
                        return ({
                            ...message,
                            question: formatContent(message.question),
                            response: queryParams.get('chatType') === 'image' ? message.generated_image : formatContent(message.response)
                        })
                    });
                    if (queryParams.get('thread')) {
                        setMessages(formattedMessages);
                    }
                    // Connect user socket
                    connectUserSocket();
                    handleSuggestionClick("");
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.log("err: ", err);
                globalContext.setLoader(false);
                if (err.response.status === 401) {
                    globalContext.setSessionComplete(true)
                }
                navigate("/?chatType=text", { replace: true });
            })
        } else {
            // globalContext.setLoader(true);
            apiService.getGuestThreadMessage(queryParams.get('thread')).then((res) => {
                if (res.status === 200) {
                    const formattedMessages = res.data.data.map(message => ({
                        ...message,
                        response: formatContent(message.response)
                    }));
                    if (queryParams.get('thread')) {
                        setMessages(formattedMessages);
                    }
                    handleSuggestionClick("");
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.log("err: ", err);
                globalContext.setLoader(false);
                navigate("/?chatType=text", { replace: true });
            })
        }
    }

    const renderMessages = () => {
        if (messages.length > 0) {
            return messages?.map((msg, index) => (
                <div key={index}>
                    {msg.question &&
                        <div key={`${index}_ques`} className="user-chat-outer question-area">
                            <img src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image : require("../assets/images/profile.png")} alt="img" />
                            <div className="user-chat-right">
                                <h6>{globalContext.loggedIn ? globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email : 'User'}</h6>
                                <p dangerouslySetInnerHTML={{ __html: msg.question }} />
                            </div>
                        </div>
                    }
                    {msg.response &&
                        <div key={`${index}_res`} className="user-chat-outer">
                            <img src={require("../assets/images/helpr-profile.png")} alt="img" />
                            <div className="user-chat-right">
                                <h6>Helpr</h6>
                                {
                                    queryParams.get('chatType') === 'image' ?
                                        <img className='generated-image' title="Click to view full image" src={msg.response} alt="img" onClick={() => setFullImageScreen(msg.response)} />
                                        :
                                        <p dangerouslySetInnerHTML={{ __html: msg.response }} />
                                }
                            </div>
                        </div>
                    }
                </div>
            ));
        }
    };

    const generateImage = () => {
        globalContext.setLoader(true);
        globalContext.setGenerating(true);
        const data = { prompt: question };
        if (thread) {
            data.thread = thread;
        }
        apiService.generateImage(data).then((res) => {
            if (res.status === 201) {
                globalContext.setLoader(false);
                setMessages([...messages, { "question": formatContent(question), "response": res.data.data.generated_image }]);
                setThread(res.data.data.thread);
                // setQuestion("");
                globalContext.setGenerating(false);
                if (res.data.data.thread && res.data.data.thread !== queryParams.get('thread')) {
                    navigate(`/?chatType=${queryParams.get('chatType')}&thread=${res.data.data.thread}`, { replace: true });
                    globalContext.setThreads(prevThreads => {
                        const existingThreadIndex = prevThreads?.findIndex(thread => thread.id === res.data.data.thread);
                        if (existingThreadIndex !== -1) {
                            return prevThreads;
                        } else {
                            return [{ id: res.data.data.thread, title: question, thread_type: queryParams.get('chatType') }, ...prevThreads];
                        }
                    })
                }
                handleSuggestionClick("");
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setGenerating(false);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }

    const sendQuestion = () => {
        console.log("connectionStatus: ", connectionStatus);
        if (!globalContext.generating && question.trim()) {
            if (queryParams.get("chatType") === "text" || queryParams.get("chatType") === "pdf" || queryParams.get("chatType") === "link") {
                if (connectionStatus === "Open") {
                    globalContext.setGenerating(true);
                    const data = { "message": question };
                    if (thread) {
                        data.thread = thread;
                    }
                    sendMessage(JSON.stringify(data));
                    setMessages([...messages, { "question": formatContent(question), "response": "Typing..." }]);
                    setQuestion("");
                }
            } else if (queryParams.get("chatType") === "image") {
                handleScroll();
                generateImage()
            }
        }
    }

    const subscribe = () => {
        if (!globalContext.loggedIn) {
            globalContext.setLock(false);
            // globalContext.setShowPlanScreen(true);
            // navigate("/?chatType=text&action=login", { replace: true });
            navigate(`/checkout?trial=${globalContext.planList[0].id}`, { replace: true });
        } else {
            globalContext.setLock(false);
            globalContext.setPlanModal(true);
        }
    }

    const uploadPdfFiles = () => {
        globalContext.setLoader(true);
        const formData = new FormData();
        for (let i = 0; i < pdfFiles.length; i++) {
            formData.append("files", pdfFiles[i]);
        }
        apiService.uploadPdfFiles(formData).then((res) => {
            if (res.status === 200) {
                setPdfFiles([]);
                setMessages([]);
                setThread(res.data.data.id);
                setQuestion("");
                globalContext.setLoader(false);
                navigate(`/?chatType=pdf&thread=${res.data.data.id}`, { replace: true });
                globalContext.setThreads(prevThreads => {
                    return [{ id: res.data.data.id, title: res.data.data.title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                })
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            Swal.fire("Error", err.response.data.detail, "error");
            setPdfFiles([]);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }

    function isValidHttpUrl(str) {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
          'i'
        );
        return pattern.test(str);
    }      

    const uploadLink = () => {
        toast.dismiss();
        if (!link) {
            toast.error("Please enter a link.");
            return;
        }
        if (!isValidHttpUrl(link)) {
            toast.error("Please enter a valid link.");
            return;
        }
        globalContext.setLoader(true);
        const data = { "link": link }
        apiService.uploadLink(data).then((res) => {
            if (res.status === 200) {
                setLink("");
                setMessages([]);
                setThread(res.data.data.id);
                setQuestion("");
                globalContext.setLoader(false);
                navigate(`/?chatType=link&thread=${res.data.data.id}`, { replace: true });
                globalContext.setThreads(prevThreads => {
                    return [{ id: res.data.data.id, title: res.data.data.title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                })
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            Swal.fire("Error", err.response.data.detail, "error");
            setLink("");
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }
    
    const checkSubscription = (e) => {
        e.preventDefault();
        navigate("/account-setting?active=profile")
    }   

    window.checkSubscription = checkSubscription;

    return (
        <>
            <div className="common-top-spacing">
                <section className="chat-outer">
                    {fullImageScreen && <ImageFullScreen image={fullImageScreen} setImage={setFullImageScreen} />}
                    <Container>
                        <div className="chat-inner">
                            <div className="chat-header">

                                {messages?.length === 0 &&
                                    <>
                                        {location.pathname === '/' && queryParams.get('chatType') === 'text' ?
                                            <>
                                                <>
                                                    <h1>
                                                        Hello, <img src={require("../assets/images/waving.png")} alt="img" />
                                                        {globalContext.loggedIn ? globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email : "User"}
                                                    </h1>
                                                    <h2>How can I help you today?</h2>
                                                    <Row>
                                                        {DUMMY_QUESTIONS.map((item, index) =>
                                                            <Col md={4} xl={4} xxl={3} className="mb-3" key={index} onClick={() => handleSuggestionClick(item)}>
                                                                <div className="suggestions-options">
                                                                    <p>{item}</p>
                                                                    <img src={require("../assets/images/arrow.png")} alt="img" />
                                                                </div>
                                                            </Col>)}
                                                    </Row>
                                                </>
                                            </>
                                            : location.pathname === '/' && queryParams.get('chatType') === 'image' ?
                                                <ImageGeneration questions={DUMMY_IMG_QUESTIONS} handleClick={handleSuggestionClick} /> :
                                                location.pathname === '/' && queryParams.get('chatType') === 'pdf' && !queryParams.get('thread') ?
                                                    <UploadPdf pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} uploadPdfFiles={uploadPdfFiles} /> :
                                                    location.pathname === '/' && queryParams.get('chatType') === 'link' && !queryParams.get('thread') ?
                                                        <LinkTool link={link} setLink={setLink} uploadLink={uploadLink} /> : location.pathname === '/' && queryParams.get('chatType') === 'pdf' && queryParams.get('thread') ? <p className='pdf-complete'>The PDF upload is complete. Feel free to ask your questions now.</p> : location.pathname === '/' && queryParams.get('chatType') === 'link' && queryParams.get('thread') ? <p className='pdf-complete'>The link has been added successfully. Feel free to ask your questions now.</p> : <></>}
                                    </>
                                }

                                {globalContext.lock &&
                                    <div className="blur-overlay">
                                        <img src={require("../assets/images/lock.png")} alt="img" />
                                        <p>Unlock advanced features of Helpr with a subscription plan.</p>

                                        <Button type="button" variant="unset" onClick={() => subscribe()}>
                                            Subscribe
                                        </Button>
                                    </div>
                                }

                                {renderMessages()}
                                <div ref={messagesEndRef} />

                            </div>
                            <div className="chat-footer">
                                {(queryParams.get("chatType") === "text" || queryParams.get("chatType") === "image" || queryParams.get("thread")) &&
                                    <>
                                        <div className="footer-inner">
                                            <Form.Control ref={textareaRef} as="textarea" rows={3} placeholder="Enter prompt here..." value={question} onChange={(e) => setQuestion(e.target.value)} disabled={globalContext.lock || globalContext.disableGeneration} onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    sendQuestion();
                                                }
                                            }}
                                            />
                                            <img className={globalContext.generating || globalContext.lock || !question ? `send-message` : ''} src={require("../assets/images/send.png")} alt="img" onClick={() => sendQuestion()} />
                                        </div>
                                        <p>Helpr may display inaccurate info, including about people, so double-check its responses.</p>
                                    </>
                                }
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default Chat;
