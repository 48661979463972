import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ContactUs from "./ContactUs"
import { Link, useLocation } from "react-router-dom"

export const Footer = () => {

    const location = useLocation();
    const [show, setShow] = useState(false)

    return (
        <>
        <div className="footer">
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="footer-inner-left">
                            <p>© {new Date().getFullYear()}. All rights reserved</p>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="footer-inner-right">
                            <ul>
                                <li><Link to={location.pathname==="/get-help" ? "/help-privacy-policy" : "/privacy-policy"}>Privacy Policy </Link></li>
                                <li><Link to={location.pathname==="/get-help" ? "/help-term-condition" : "/term-condition"}>Terms & Condition</Link></li>
                                <li><a role="button" onClick={() => setShow(true)}>Contact Us</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactUs show={show} setShow={setShow} />
        </div>
        </>
    )
}