// Live
// export const socketUrl = "wss://api.helpr.chat/ws/"
// export const apiUrl = "https://api.helpr.chat/api/"

// Staging
export const socketUrl = "wss://staging-api.helpr.chat/ws/"
export const apiUrl = "https://staging-api.helpr.chat/api/"

// Local
// export const socketUrl = "ws://localhost:8000/ws/"
// export const apiUrl = "http://localhost:8000/api/"

// Paddle Sandbox
export const paddleClientToken = "test_2d3be3c97af8b25a2f607095bf7"