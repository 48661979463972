import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { GlobalContext } from "../context/GlobalContext";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

const AccountSetting = () => {

    const globalContext = useContext(GlobalContext);
    const [queryParams] = useSearchParams();
    const { register, handleSubmit, setValue, formState: { errors }, setError } = useForm();
    const { register: passRegister, handleSubmit: passHandleSubmit, reset: passReset, formState: { errors: passErrors }, setError: passSetError } = useForm();
    const [passType1, setPassType1] = useState("password");
    const [passType2, setPassType2] = useState("password");
    const [passType3, setPassType3] = useState("password");
    const [subscription, setSubscription] = useState(null);
    const [activeKey, setActiveKey] = useState("home");
    const [subLoader, setSubLoader] = useState(false);
    const [img, setImg] = useState({ file: "", src: "" });

    useEffect(() => {
        // set values for profile
        setValue("name", globalContext.userInfo?.name)
        setValue("email", globalContext.userInfo?.email)
        setSubLoader(true);
        setTimeout(() => {
            getSubscriptionData();
        }, 1500);
    }, []);

    useEffect(() => {
        const activeType = queryParams.get("active");
        if (activeType) setActiveKey(activeType);
    }, [queryParams]);

    const updateProfile = (data) => {
        globalContext.setLoader(true);
        apiService.updateProfile(data, globalContext.userInfo?.id).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setLoader(false);
                toast.success("Profile data updated successfully.");
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(false);
            }
            setError('email', { type: 'custom', message: err.response.data.detail });
        })
    }

    const updatePassword = (data) => {
        if (data.new_password !== data.confirm_password) {
            passSetError('confirm_password', { type: 'custom', message: "New password and confirm password doesn't match." });
            return;
        }
        globalContext.setLoader(true);
        apiService.updatePassword(data).then((res) => {
            if (res.status === 200) {
                globalContext.setLoader(false);
                passReset()
                toast.success(res.data.detail);
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(false);
            }
            passSetError('old_password', { type: 'custom', message: err.response.data.detail });
        })
    }

    const uploadImage = (action) => {
        if (action === "upload" && !img.file) {
            toast.error("Please select an image.");
            return;
        } else if (action === "remove" && !globalContext.userInfo?.profile_image) {
            toast.error("No profile image to remove.");
            return;
        }
        globalContext.setLoader(true);
        const formData = new FormData();
        if (action === "upload") formData.append("profile_image", img.file);
        if (action === "remove") formData.append("profile_image", "");
        apiService.updateProfile(formData, globalContext.userInfo?.id).then((res) => {
            if (res.status === 200) {
                setImg({ file: "", src: "" });
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setLoader(false);
                toast.success("Profile data updated successfully.");
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(false);
            }
            toast.error(err.response.data.detail);
        })
    }

    const getSubscriptionData = () => {
        setSubLoader(true);
        apiService.getSubscription().then((res) => {
            setSubLoader(false);
            if (res.status === 200) {
                setSubscription(res.data.data);
                setTimeout(() => {
                    globalContext.setRefreshProfile(!globalContext.refreshProfile);
                }, 1000);
            }
        }).catch((err) => {
            setSubLoader(false);
            console.log("err: ", err);
        })
    }

    const cancelSubscription = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Dismiss',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                apiService.cancelSubscription().then((res) => {
                    if (res.status === 200) {
                        toast.success("Subscription cancelled successfully.");
                        setTimeout(() => {
                            getSubscriptionData();
                        }, 1000);
                        setTimeout(() => {
                            globalContext.setRefreshProfile(!globalContext.refreshProfile);
                        }, 2000);
                    }
                }).catch((err) => {
                    console.log("err: ", err);
                })
            }
        })
    }

    const changeTab = (k) => {
        setActiveKey(k);
        if (k === "profile") {
            getSubscriptionData();
        }
    }

    return (
        <>
            <div className="account-setting-outer-div">
                <div className="common-top-spacing">
                    <div className="common-height-footer">
                        <section className="account-setting-outer">
                            <Container>
                                <Tabs
                                    activeKey={activeKey}
                                    onSelect={(k) => changeTab(k)}
                                    id="uncontrolled-tab-example"
                                    className="mb-4"
                                >
                                    <Tab eventKey="home" title="Account Settings">
                                        <Row>
                                            <Col md={6} className="mb-4">
                                                <div className="account-setting-content">
                                                    <div className="account-setting-content-inner">
                                                        <div className="account-seeting-profile">
                                                            <img src={img.src ? img.src : globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image : require("../assets/images/profile.png")} alt="img" />
                                                            <div className="image-upload-area">
                                                                <i className="fa-solid fa-pen"></i>
                                                                <Form.Control type="file" accept="image/*" size="lg" onChange={(e) =>
                                                                    setImg({
                                                                        file: e.target.files[0],
                                                                        src: URL.createObjectURL(e.target.files[0]),
                                                                    })
                                                                } />
                                                            </div>
                                                        </div>

                                                        <div className="account-content-right">
                                                            <h5>Change Profile Picture</h5>
                                                            <h6>Upload a new photo to update your profile picture.</h6>
                                                            <p>Pick a photo up to 4MB in size.</p>

                                                        </div>
                                                    </div>

                                                    <div className="account-content-btn-bottom">
                                                        <div className="upload-photo-outer">
                                                            <Button onClick={() => uploadImage("upload")} type="button" variant="unset" className="upload" disabled={img.file ? false : true}>Upload Photo</Button>
                                                        </div>
                                                        {globalContext.userInfo?.profile_image && <Button onClick={() => uploadImage("remove")} type="button" variant="unset" className="remove">Remove Photo</Button>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className="mb-4">
                                                <div className="change-profile-area">
                                                    <h5>Change Profile Details</h5>
                                                    <Form onSubmit={handleSubmit(updateProfile)}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Form.Control maxLength={50} type="text" placeholder="Enter Name" {...register("name")} onKeyDown={(e) => { if (e.key === " " && e.target.value.length === 0) e.preventDefault() }} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Email Address</Form.Label>
                                                                    <Form.Control type="email" placeholder="Enter Email" {...register("email", {
                                                                        required: "Email is required.", pattern: {
                                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                            message: "Invalid email address."
                                                                        }
                                                                    })} />
                                                                    {errors.email && <span className="err-msg">{errors.email.message}</span>}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className="account-content-btn-bottom">
                                                            <Button type="submit" variant="unset" className="upload">Save</Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Col>
                                            <hr></hr>
                                            <Col md={6} className="mt-2 mb-4">
                                                <div className="change-profile-area change-password-area">
                                                    <h5>Change Password</h5>
                                                    <Form onSubmit={passHandleSubmit(updatePassword)}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Old Password</Form.Label>
                                                            <div className="password-area">
                                                                <div className="password-area-inner">
                                                                    <Form.Control type={passType1} placeholder="Enter Old Password" {...passRegister("old_password", { required: "Old Password is required." })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                                    <i onClick={() => setPassType1(passType1 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType1 === "password" ? "-slash" : ""}`} />
                                                                </div>
                                                                {passErrors.old_password && <span className="err-msg">{passErrors.old_password.message}</span>}
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>New Password</Form.Label>
                                                            <div className="password-area">
                                                                <div className="password-area-inner">
                                                                    <Form.Control type={passType2} placeholder="Enter New Password" {...passRegister("new_password", {
                                                                        required: "New Password is required.", pattern: {
                                                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                                            message: "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
                                                                        }
                                                                    })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                                    <i onClick={() => setPassType2(passType2 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType2 === "password" ? "-slash" : ""}`} />
                                                                </div>
                                                                {passErrors.new_password && <span className="err-msg">{passErrors.new_password.message}</span>}
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Confirm New Password</Form.Label>
                                                            <div className="password-area">
                                                                <div className="password-area-inner">
                                                                    <Form.Control type={passType3} placeholder="Enter Confirm New Password" {...passRegister("confirm_password", { required: "Confirm New Password is required." })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                                    <i onClick={() => setPassType3(passType3 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType3 === "password" ? "-slash" : ""}`} />
                                                                </div>
                                                                {passErrors.confirm_password && <span className="err-msg">{passErrors.confirm_password.message}</span>}
                                                            </div>
                                                        </Form.Group>
                                                        <div className="account-content-btn-bottom">
                                                            <Button type="submit" variant="unset" className="upload">Submit</Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="profile" title="My Plan">
                                        <Row>
                                            {subscription && Object.keys(subscription).length > 0 ?
                                                <Col md={6}>
                                                    <div className="my-plan-outer">
                                                        {subscription?.status == "trialing" && <div className='subscription-vector-outer'>
                                                            <div className='subscription-vector'>
                                                                <img src={require("../assets/images/subscription-vector.png")} alt='img' />
                                                            </div>
                                                            <p>7 Days Free Trial Period</p>
                                                        </div>}
                                                        <div className="setting-pricing-area">
                                                            <div className={`setting-pricing-head-area${subscription?.status != "trialing" ? " no-plan-head": ""}`}>
                                                                <div className="setting-pricing-head-left">
                                                                    <h6>{subscription?.items[0]?.price?.billing_cycle?.frequency} {subscription?.items[0]?.price?.billing_cycle?.interval}</h6>
                                                                    <p>${subscription?.items[0]?.price?.unit_price?.amount / 100}</p>
                                                                </div>
                                                                <div className="setting-pricing-head-left per-day-cost">
                                                                    <h3><span>$0.66 Per Day </span></h3>
                                                                    <p>Active Till: {moment(subscription?.current_billing_period?.ends_at).format("MMM DD YYYY")}</p>
                                                                </div>

                                                            </div>
                                                            <div className="setting-pricing-body">
                                                                <ul>
                                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Unlimited access to text chat</li>
                                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Generate unlimited images</li>
                                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Upload PDFs and ask unlimited questions</li>
                                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Add links and ask unlimited questions</li>
                                                                </ul>
                                                                {subscription?.next_billed_at ? <Button onClick={() => cancelSubscription()} variant="unset" className="upload">Cancel Subscription</Button> : <p className="sub-cancel">Subscription Cancelled</p>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col> :
                                                !subLoader ?
                                                    <Col md={12}>
                                                        <div className="my-plan-outer">
                                                            <div className="no-plan-area">
                                                                <img src={require("../assets/images/no-plan.png")} alt="img" />
                                                                <p>No Active Plan</p>
                                                                <Button variant="unset" className="upload" onClick={() => globalContext.setPlanModal(true)}>Upgrade to Pro</Button>
                                                            </div>

                                                        </div>
                                                    </Col> : <></>
                                            }
                                        </Row>
                                    </Tab>

                                </Tabs>
                            </Container>

                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AccountSetting;