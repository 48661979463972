import React, { useContext, useEffect, useState } from "react";
import { paddleClientToken } from "../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiService } from "../service/api.service";
import { GlobalContext } from "../context/GlobalContext";

const Payment = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const [transaction, setTransaction] = useState(null);
  const [checkoutVisible, setCheckoutVisible] = useState(true);

  useEffect(() => {
    // Setup Sandbox
    window.Paddle.Environment.set("sandbox");
    // Initialize Paddle
    window.Paddle.Initialize({
      token: paddleClientToken,
      eventCallback: function (data) {
        console.log("Callback data", data);
      },
    });
  }, []);

  useEffect(() => {
    if (queryParams.get("transaction") || queryParams.get("trial-transaction")) {

      let transaction = null
      if (queryParams.get("transaction")) {
        transaction = queryParams.get("transaction");
      } else if (queryParams.get("trial-transaction")) {
        transaction = queryParams.get("trial-transaction");
      }

      // Open Checkout
      window.Paddle.Checkout.open({
        settings: {
          allowDiscountRemoval: true,
          allowLogout: false,
          displayMode: "inline",
          locale: "en",
          showAddDiscounts: true,
          showAddTaxId: true,
          frameTarget: "checkout-frame",
          frameStyle:
            "width: 500px; background: rgb(101 163 255 / 6%); border-right: 1px solid rgba(0, 0, 0, 0.07); height: 100vh; padding-top: 80px;",
          theme: "light",
          allowedPaymentMethods: ["card"],
          successUrl: `${queryParams.get("transaction") ? `${window.location.origin}/?chatType=text` : `${window.location.origin}/set-password/${transaction}`}`,
        },
        transactionId: transaction,
      });
      setTimeout(() => getTransaction(transaction), 500);

    }

    if (queryParams.get("plan")) {
      createTransaction();
    }
    if (queryParams.get("trial")) {
      createTrialTransaction();
    }

    return () => {
      if (window.Paddle && window.Paddle.Checkout) {
        window.Paddle.Checkout.close();
      }
    };
  }, [queryParams]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const frame = document.querySelector('.checkout-frame');
      if (frame) {
        setCheckoutVisible(frame.offsetWidth !== 0 && frame.offsetHeight !== 0);
      }
    }, 100);

    return () => clearInterval(intervalId);

  }, []);

  const createTransaction = () => {
    globalContext.setLoader(true);
    apiService
      .createTransaction({ plan_id: queryParams.get("plan") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          navigate(`/checkout?transaction=${res.data.data.id}`, {
            replace: true,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };

  const createTrialTransaction = () => {
    globalContext.setLoader(true);
    apiService
      .createTrialTransaction({ plan_id: queryParams.get("trial") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          navigate(`/checkout?trial-transaction=${res.data.data.id}`, {
            replace: true,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };

  const getTransaction = (transaction) => {
    apiService
      .getTransaction(transaction)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.details?.totals.tax == 0 && res.data?.data?.details?.totals.grand_total != 0) {
            getTransaction(transaction);
          } else {
            setTransaction(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  return (
    <>
      <div className="checkout-outer">
        <div className="checkout-frame"></div>
        <div className="price-total">
          {checkoutVisible &&
            <div className="pricing-total-inner">
              <h5 onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>Back
              </h5>
              <div className="pricing-total-area">
                {transaction ? (
                  <>
                    <div className="price-total-inner">
                      <p>Sub Total:</p>
                      <h6> ${transaction?.details?.totals.subtotal / 100}</h6>
                    </div>
                    <div className="price-total-inner">
                      <p>Tax:</p>
                      <h6>${transaction?.details?.totals.tax / 100}</h6>
                    </div>
                    {transaction?.details?.totals.discount != 0 && (
                      <div className="price-total-inner">
                        <p>Discount:</p>
                        <h6> ${transaction?.details?.totals.discount / 100}</h6>
                      </div>
                    )}
                    <div className="grand-total">
                      <p>Grand Total: </p>
                      <h6>${transaction?.details?.totals.grand_total / 100}</h6>
                    </div>
                  </>
                ): 
                <div className="pricing-loader-outer">
                  <span className="pricing-loader"></span>
                </div>}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Payment;
