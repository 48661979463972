import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import Swal from "sweetalert2";
import { QuestionsAnswers } from "../common/QuestionsAnswers";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Footer } from "../common/Footer";
export const LandingPage = () => {

    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const globalContext = useContext(GlobalContext);

    const [queryParams] = useSearchParams();
    const [query, setQuery] = useState("");
    const [messages, setMessages] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [passType, setPassType] = useState("password");
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [chatView, setChatView] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setError: setError1, reset } = useForm();

    useEffect(() => {
        if (queryParams.get('action') === 'login') {
            setShowLogin(true);
        }
    }, [queryParams]);

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.assistant && lastMessage.assistant.includes("Subscribe now!") && goToCheckout) {
                setTimeout(() => {
                    buyFreeTrial();
                }, 3500);
            }
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        const chatbotChat = localStorage.getItem("chatbot-chat");
        if (chatbotChat) {
            setMessages(JSON.parse(chatbotChat));
        } else {
            setMessages([{ "assistant": QuestionsAnswers[0] }]);
            localStorage.setItem("chatbot-chat", JSON.stringify([{ "assistant": QuestionsAnswers[0] }]));
        }
        getPlanList();
    }, []);

    const getPlanList = () => {
        apiService.getPlans().then((res) => {
            if (res.status === 200) {
                setSelectedPlan(res.data.data[0].id);
            }
        }).catch((err) => {
            console.log("plan list error: ", err);
        })
    }

    const sendMessage = () => {
        if (query && messages.length < 4) {
            setGoToCheckout(true);
            const oldMsgs = [...messages]
            oldMsgs[oldMsgs.length - 1].user = query;
            oldMsgs.push({ "assistant": QuestionsAnswers[oldMsgs.length] });
            setMessages(oldMsgs);
            localStorage.setItem("chatbot-chat", JSON.stringify(oldMsgs));
            setQuery("");
        }
    }

    const onSubmitLogin = (data) => {
        globalContext.setLoader(true);
        apiService.login(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("login error: ", err);
            globalContext.setLoader(false);
            setError('password', { type: 'custom', message: err.response.data.detail });
        })
    }

    const onSubmitForgot = (data) => {
        globalContext.setLoader(true);
        apiService.forgotPassword(data).then((res) => {
            globalContext.setLoader(false);
            Swal.fire(res.data.detail);
            if (res.status === 200) {
                setShowForgot(false);
                reset();
            }
        }).catch((err) => {
            console.log("forgot error: ", err);
            globalContext.setLoader(false);
            setError1('email', { type: 'custom', message: err.response.data.detail });
        })
    }

    const buyFreeTrial = () => {
        navigate(`/checkout?trial=${selectedPlan}`);
    }

    const handleClick = (e) => {
        e.preventDefault();
        buyFreeTrial();
    };
    window.handleClick = handleClick;

    return (
        <>
        <div className="main-landing-page">
            {/* header section*/}
            <header>
                <Container>
                    <div className="header-logo">
                        <img src={require("../assets/images/logo-main.svg").default} alt="img" />
                        <Button className="login-btn" onClick={() => setShowLogin(true)}>
                            Log In
                        </Button>
                    </div>
                </Container>
            </header>
            {/* header section*/}

            {/* hero-section */}
            <section className="hero-section">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="hero-content">
                                <h1>Get help for any issue 24/7. Chat with your own AI expert now.</h1>
                                <ul>
                                    <li>Get your answer in minutes</li>
                                    <li>Personalized help from verified Experts</li>
                                    <li>Unlimited chats - Join for Free.</li>
                                </ul>
                                <Button className="get-started-btn" onClick={() => buyFreeTrial()}>Get Started <svg className="ms-2" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.2203 10.9062L11.3313 1.14895L1.57769 1.43685M10.2334 2.26696L0.821276 11.8513" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></Button>
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="hero-img text-right">
                                <img src={require("../assets/images/about-img.png")} alt="img" />
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </section>
            {/* hero-section */}

            {/* chat section */}
            <div className={chatView ? "chat-container": "chat-hide-container"}>
                <div className="th-prominent-chat dqt-chat">
                    <div className="th-prominent-chat-window">
                        <div className="chat-header-main">
                            <div className="expert-profile expert-profile--blue">
                                <div className="avatar"><div className="avatar-wrapper">
                                    <img src={require("../assets/images/user.png")} alt="img" />
                                </div>
                                </div>
                                <div className="details">
                                    <p className="name">Adam Smith </p>
                                    <p className="title">Consultant</p>
                                    <p className="credentials">10 years of experience helping hundreds of people</p>
                                    <div className="rating">

                                        <p className="question-count">
                                            <span className="me-2">
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                            <strong className="count-number">8,535</strong> Satisfied customers</p>
                                    </div>
                                </div>
                            </div>
                            <i class="fa-solid fa-xmark close-main-icon" onClick={() => setChatView(false)} />
                        </div>
                        <div data-testid="dialog" className="dialog body">
                            <div className="content">
                                <div className="conversation">
                                    {messages?.map((msg, index) =>
                                        <div key={index}>
                                            <div className="expert message th-chat-message">
                                                <img className="right-message-img" src={require("../assets/images/user.png")} alt="img" />
                                                <div className="content">
                                                    <span className="name">Adam Smith, Consultant </span>
                                                    <div className="text">
                                                        <span dangerouslySetInnerHTML={{ __html: msg?.assistant }} />
                                                    </div>
                                                </div>
                                            </div>
                                            {msg?.user &&
                                                <div className="customer message th-chat-message">
                                                    <div className="content">
                                                        <div className="text">
                                                            <span dangerouslySetInnerHTML={{ __html: msg?.user }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div ref={messagesEndRef} />
                        </div>
                        <div className="th-chat-integrated-question-box">
                            <div className="wrapper">
                                <div className="text-box">
                                    <textarea placeholder="Type your message..." value={query} onChange={(e) => setQuery(e.target.value.trimStart())} data-testid="chat-input" className="text-area" onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            sendMessage();
                                        }
                                    }}
                                    />
                                </div>
                                <div className="bottom">
                                    <input type="button" value="Send" onClick={sendMessage} disabled={!query} className="send-btn submit-button dqt-send disabled" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button onClick={() => setChatView(true)} className="chat-support-btn">
                Chat <img src={require("../assets/images/chat-icon.gif")} />
            </Button>
            {/* chat section */}


            {/* partner section */}
            <section className="partner-section">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="partner-inner-area">
                                <img src={require("../assets/images/partner-1.png")} />
                                <img src={require("../assets/images/partner-2.png")} />
                                <img src={require("../assets/images/partner-3.png")} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* partner section */}

            {/* how it work section start */}
            <section className="how-it-work">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="how-it-works-inner">
                                <h2>How It Works </h2>
                                <p>Tell the Experts' Assistant whats going on so that she can find the best person to help you.</p>
                                <div className="how-it-works-inner-content">
                                    <img src={require("../assets/images/works1.png")} />
                                    <div className="work-content-right">
                                        <h6>Connect</h6>
                                        <p>Tell the Experts' Assistant whats going on so that she can find the best person to help you.</p>
                                    </div>
                                </div>
                                <div className="how-it-works-inner-content">
                                    <img src={require("../assets/images/works2.png")} />
                                    <div className="work-content-right">
                                        <h6>Go back-and-forth</h6>
                                        <p>Connect with the Expert, chat 1:1 till you have your answer</p>
                                    </div>
                                </div>
                                <div className="how-it-works-inner-content">
                                    <img src={require("../assets/images/works3.png")} />
                                    <div className="work-content-right">
                                        <h6>Fix it now</h6>
                                        <p>You have instant access to thousands of Experts to resolve every issue, 24/7.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* how it work section end */}


            {/* testimonial sections */}
            <section className="testimonial-section">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="testimonial-box">
                                <h2>Our clients appreciate our unwavering support and quality.</h2>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many.</p>
                                <span className="me-2 yellow-star">
                                    <i className="fa-solid fa-star me-1"></i>
                                    <i className="fa-solid fa-star me-1"></i>
                                    <i className="fa-solid fa-star me-1"></i>
                                    <i className="fa-solid fa-star me-1"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <span>(4.0/5.0)</span>
                                    <p className="blue-text">Overall Customer Ratings</p>
                                </span>
                                <div className="client-testimonial">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={0}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className="card-testimonail">
                                                <span className="me-2 yellow-star">
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span>(4.0/5.0)</span>

                                                </span>
                                                <h6>"magnis dis parturient montes"</h6>
                                                <p>Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.</p>
                                                <div className="testimonial-profile">
                                                    <img src={require("../assets/images/user.png")} />
                                                    <div className="testimonial-info">
                                                        <h5>Naeem Khan</h5>
                                                        <a>@naeemkhan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="card-testimonail">
                                                <span className="me-2 yellow-star">
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span>(4.0/5.0)</span>

                                                </span>
                                                <h6>"magnis dis parturient montes"</h6>
                                                <p>Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.</p>
                                                <div className="testimonial-profile">
                                                    <img src={require("../assets/images/user.png")} />
                                                    <div className="testimonial-info">
                                                        <h5>Naeem Khan</h5>
                                                        <a>@naeemkhan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="card-testimonail">
                                                <span className="me-2 yellow-star">
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star me-1"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span>(4.0/5.0)</span>

                                                </span>
                                                <h6>"magnis dis parturient montes"</h6>
                                                <p>Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.Lorem ipsum dolor amet consectetur cillum adipiscing elit sed do eiusmod.</p>
                                                <div className="testimonial-profile">
                                                    <img src={require("../assets/images/user.png")} />
                                                    <div className="testimonial-info">
                                                        <h5>Naeem Khan</h5>
                                                        <a>@naeemkhan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* testimonial sections */}

            {/* partner section */}
            <section className="partner-section">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="partner-inner-area">
                                <img src={require("../assets/images/partner-1.png")} />
                                <img src={require("../assets/images/partner-2.png")} />
                                <img src={require("../assets/images/partner-3.png")} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* partner section */}

            {/* pricing section start */}
            <section className="pricing-area-outer">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="pricing-area-inner">
                                <h2>Only $20/month. No commitments.</h2>
                                <p>Unlock premium digital services for just $20 per month with the freedom to top-tier solutions tailored to your needs, all without long-term commitments. </p>
                                <Button variant="unset" onClick={() => buyFreeTrial()}>Get Started <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.2203 10.9062L11.3313 1.14895L1.57769 1.43685M10.2334 2.26696L0.821276 11.8513" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* pricing section start */}


            {/* login modal */}
            <Modal show={showLogin} fullscreen={true} onHide={() => {setShowLogin(false); navigate("/get-help", { replace: true })}} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Sign In</h2>
                                    <p>Use registered email address and password.</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit(onSubmitLogin)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register("email", {
                                                required: "Email is required.", pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: "Invalid email address."
                                                }
                                            })} />
                                            {errors.email && <span className="err-msg">{errors.email.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Enter Password</Form.Label>
                                            <div className="password-area">
                                                <div className="password-area-inner">
                                                    <Form.Control type={passType} placeholder="Password" {...register("password", { required: "Password is required" })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                    <i onClick={() => setPassType(passType === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType === "password" ? "-slash" : ""}`} />
                                                </div>
                                                {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                            </div>
                                            <p className="forgot-text">
                                                <a onClick={() => setShowForgot(true)} >Forgot Password?</a>
                                            </p>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Sign In
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* forgot modal */}
            <Modal show={showForgot} fullscreen={true} onHide={() => setShowForgot(false)} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Forgot Password?</h2>
                                    <p>Enter the email address associated with your account and we’ll send you a link to reset your password.</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit1(onSubmitForgot)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register1("email", {
                                                required: "Email is required.", pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: "Invalid email address."
                                                }
                                            })} />
                                            {errors1.email && <span className="err-msg">{errors1.email.message}</span>}
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Continue
                                        </Button>
                                        <p><img src={require("../assets/images/back.png")} alt="img" />Back to <a onClick={() => setShowForgot(false)}>Sign In</a></p>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            </div>
        <Footer />
        </>
    )
}
