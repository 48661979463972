import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ImageGeneration = ({ questions, handleClick }) => {
    return (
        <>
            <div className="common-height-footer image-generation-height">
                <Container>
                    <div className="chat-inner">
                        <div className="chat-header advanced-tool-outer">
                            <Row className="justify-content-center">
                                <Col xl={8} xxl={7}>
                                    <div className="image-generation-area">
                                        <div className="image-generation-head">
                                            <img src={require("../assets/images/generate-img.png")} alt="img" />
                                            <div>
                                                <h6>Image Generation</h6>
                                                <p>Create images using words</p>                                                                                                                                                 
                                            </div>
                                        </div>
                                        <div className="image-generation-body">
                                            <h5>Image Generation</h5>
                                            <p>Experience the magic of AI with Image Generation. Instantly turn your words into stunning, captivating visuals. Embark on a creative adventure and see the power of imagination in every pixel.</p>
                                            <h6>
                                                Begin a conversation using the message box below or try one of these examples:
                                            </h6>
                                            <ul>
                                                {questions?.map((question, index) => (
                                                    <li key={index} onClick={() => handleClick(question)}>{question}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default ImageGeneration;