import { useContext, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../context/GlobalContext";
import { apiService } from "../service/api.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const ContactUs = ({ show, setShow }) => {

    const globalContext = useContext(GlobalContext);
    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

    useEffect(() => {
        if (globalContext.loggedIn) {
            setValue("name", globalContext.userInfo?.name)
            setValue("email", globalContext.userInfo?.email)
        }
    }, [show]);

    const onContactUs = (data) => {
        globalContext.setLoader(true);
        if (globalContext.loggedIn) {
            data.user = globalContext.userInfo?.id;
        }
        apiService.contactUs(data).then((res) => {
            globalContext.setLoader(false);
            Swal.fire("Success", res.data.detail, "success");
            if (res.status === 201) {
                setShow(false);
                reset();
            }
        }).catch((err) => {
            console.log("contact error: ", err);
            globalContext.setLoader(false);
            toast.error(err.response.data.detail);
        })
    }

    const handleSpace = (e) => {
        if (e.key === " " && e.target.value.length === 0) {
            e.preventDefault();
        }
    }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => setShow(false)} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={9} lg={7} xl={6} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner contact-form">
                                <div className="login-content-head">
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit(onContactUs)}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" maxLength={50} onKeyDown={handleSpace} placeholder="Enter Name" {...register("name", { required: "Name is required." })} />
                                            {errors.name && <span className="err-msg">{errors.name.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register("email", { required: "Email is required.", pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: "Invalid email address."
                                            } })} />
                                            {errors.email && <span className="err-msg">{errors.email.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control type="text" maxLength={200} onKeyDown={handleSpace} placeholder="Enter Subject" {...register("subject", { required: "Subject is required." })} />
                                            {errors.subject && <span className="err-msg">{errors.subject.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control maxLength={1000} as="textarea" onKeyDown={handleSpace} rows={3} placeholder="Enter Message" {...register("message", { required: "Message is required." })} />
                                            {errors.message && <span className="err-msg">{errors.message.message}</span>}
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ContactUs;