import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../service/api.service";

export const SetPassword = () => {

    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [email, setEmail] = useState("");
    const [transactionDetail, setTransactionDetail] = useState({});
    const [infoMessage, setInfoMessage] = useState("Hold on, we are verifying your details...");
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [passType1, setPassType1] = useState("password");

    useEffect(() => {
        getTransaction();
    }, [])

    const getTransaction = () => {
        apiService.getTransaction(params.transaction).then((res) => {
            const response = res.data.data;
            if (!response.subscription_id) {
                setTimeout(() => {
                    getTransaction();
                }, 1000)
            } else {
                setTransactionDetail(response);
                if (!response.customer_id) {
                    setInfoMessage("Invalid transaction. Please try again.");
                } else {
                    getCustomerDetail(response.customer_id, response.subscription_id);
                }
            }
        })
        .catch((err) => {
            setInfoMessage("Some error occured. Please try again later.");
            console.log("err: ", err);
        });
    }

    const getCustomerDetail = (customer_id, subscription_id) => {
        apiService.getCustomer(customer_id).then((res) => {
            if (res.status === 200) {
                if (res.data.userExist) {
                    cancelAddedSubscription(subscription_id);
                } else {
                    setEmail(res.data.data.email);
                    setLoader(false);
                }
            }
        })
        .catch((err) => {
            setInfoMessage("Some error occured. Please try again later.");
            console.log("err: ", err);
        });
    }

    const cancelAddedSubscription = (subscription_id) => {
        const payload = {subscription_id: subscription_id}
        apiService.cancelAddedSub(payload).then((res) => {
            setInfoMessage("Seems like you already have an account. Please login.");
            setTimeout(() => {
                navigate("/?action=login");
            }, 3000);
        }).catch((err) => {
            setInfoMessage("Seems like you already have an account. Please login.");
            setTimeout(() => {
                navigate("/?action=login");
            }, 3000);
        })
    }

    const setYourPassword = (data) => {
        globalContext.setLoader(true);
        const payload = {
            email: email,
            password: data.password,
            has_trial: false,
            customer_id: transactionDetail.customer_id,
            subscription_id: transactionDetail.subscription_id
        }
        apiService.signup(payload).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 201) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("signup error: ", err);
            globalContext.setLoader(false);
        })
    }

    return (
        <div className="common-top-spacing">
            <section className="account-setting-outer">
                <Container>
                    <Row className="justify-content-center">
                        {!loader ?<Col md={10}  xl={6} xxl={5} className="col-12 mt-5">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Set Password</h2>
                                    <p>Set Password for your account, If skipped, you won't be able to recover your account!</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit(setYourPassword)}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <div className="password-area">
                                            <div className="password-area-inner">
                                            <Form.Control type={passType1} placeholder="Enter Password" {...register("password", { required: "Password is required.", pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                message: "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
                                            }})} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }}/>
                                            <i onClick={() => setPassType1(passType1 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType1 === "password" ? "-slash" : ""}`} />
                                            </div>
                                            {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                            </div>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Set Password
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        :
                        <Col md={10}  xl={6} xxl={5} className="col-12 mt-5">
                            <div className="hold-message">
                                <img src={infoMessage.includes("Hold on") ? require("../assets/images/waiting.gif") : require("../assets/images/error.gif")} alt="loader" />
                                <h4>{infoMessage}</h4>
                            </div>
                        </Col>
                        }
                    </Row>
                </Container>
            </section>
        </div>
    )
}