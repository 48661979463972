import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import Swal from "sweetalert2";

const Header = ({open, setOpen}) => {

    const ADVANCE_FEATURES = [
        {
            "name": "Image Generation",
            "image": require("../assets/images/generate-img.png"),
            "description": "Create images using words",
            "route": "/?chatType=image",
            "type": "image"
        },
        {
            "name": "Upload PDF",
            "image": require("../assets/images/pdf.png"),
            "description": "Chat with any PDF",
            "route": "/?chatType=pdf",
            "type": "pdf"
        },
        {
            "name": "Link",
            "image": require("../assets/images/link.png"),
            "description": "Paste a Link & Ask",
            "route": "/?chatType=link",
            "type": "link"
        }
    ]

    const navigate = useNavigate();
    const location = useLocation();
    const [queryParams] = useSearchParams();
    const globalContext = useContext(GlobalContext);

    const [scroll, setScroll] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [passType, setPassType] = useState("password");
    const [selectedPlan, setSelectedPlan] = useState(null);

    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setError: setError1, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setError: setError2 } = useForm();

    useEffect(() => {
        if (!globalContext.loggedIn && (location.pathname !== "/" && !location.pathname.includes("/reset-password/") && location.pathname !== "/privacy-policy" && location.pathname !== "/term-condition")) {
            navigate("/?chatType=text", { replace: true });
        }
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    }, [location]);

    useEffect(() => {
        if (queryParams.get('action') === 'login') {
            setShowLogin(true);
        }
        if (queryParams.get('chatType')) {
            const chatType = queryParams.get('chatType');
            if (["image", "pdf", "link"].includes(chatType)) {
                if (globalContext.loggedIn) {
                    if (!globalContext.userInfo?.subscription_id) {
                        globalContext.setPlanModal(true);
                    }
                } else {
                    setShowLogin(true);
                }
            }
        }
        if (["pdf", "image", "link"].includes(queryParams.get("chatType")) && (!globalContext.loggedIn || !globalContext.userInfo?.subscription_id)) {
            globalContext.setDisableGeneration(true);
        } else {
            globalContext.setDisableGeneration(false);
        }
    }, [queryParams, location]);

    useEffect(() => {
        getPlanList();
    }, [globalContext.refreshProfile, location]);

    useEffect(() => {
        if (globalContext.loggedIn) {
            getMyDetails();
        }
    }, [globalContext.refreshProfile, location]);


    const getMyDetails = () => {
        apiService.getMyProfile().then((res) => {
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setUserInfo(res.data.data);
            }
        }).catch((err) => {
            console.log("my details error: ", err);
        })
    }

    const getPlanList = () => {
        apiService.getPlans().then((res) => {
            if (res.status === 200) {
                globalContext.setPlanList(res.data.data);
                setSelectedPlan(res.data.data[0].id);
            }
        }).catch((err) => {
            console.log("plan list error: ", err);
        })
    }

    const onSubmitLogin = (data) => {
        globalContext.setLoader(true);
        apiService.login(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("login error: ", err);
            globalContext.setLoader(false);
            setError('password', { type: 'custom', message: err.response.data.detail });
        })
    }

    const onSubmitSignup = (data) => {
        globalContext.setLoader(true);
        apiService.signup(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 201) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                setShowSignup(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("signup error: ", err);
            globalContext.setLoader(false);
            setError2('email', { type: 'custom', message: err.response.data.detail });
        })
    }

    const logout = () => {
        globalContext.setLoader(true);
        apiService.logout().then((res) => {
            globalContext.setLoader(false);
            if (res.status === 200) {
                localStorage.removeItem("user-info");
                globalContext.setLoggedIn(false);
                globalContext.setUserInfo({});
                navigate("/", { replace: true });
            }
        }).catch((err) => {
            console.log("logout error: ", err);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                localStorage.removeItem("user-info");
                globalContext.setLoggedIn(false);
                globalContext.setUserInfo({});
                navigate("/", { replace: true });
            }
        })
    }

    const onSubmitForgot = (data) => {
        globalContext.setLoader(true);
        apiService.forgotPassword(data).then((res) => {
            globalContext.setLoader(false);
            Swal.fire(res.data.detail);
            if (res.status === 200) {
                setShowForgot(false);
                reset();
            }
        }).catch((err) => {
            console.log("forgot error: ", err);
            globalContext.setLoader(false);
            setError1('email', { type: 'custom', message: err.response.data.detail });
        })
    }

    const changeChatModel = (route) => {
        if (globalContext.loggedIn) {
            if (globalContext.userInfo?.subscription_id) {
                navigate(route, { replace: true });
            } else {
                globalContext.setPlanModal(true);
            }
        } else {
            setShowLogin(true);
        }
    }

    function handleShowSignup() {
        setShowSignup(true);
    }

    const buyPlan = () => {
        navigate(`/checkout?plan=${selectedPlan}`);
        globalContext.setPlanModal(false);
    }

    const upgradePlan = () => {
        apiService.getMyProfile().then((res) => {
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setUserInfo(res.data.data);
                if (!res.data.data.subscription_id) {
                    globalContext.setPlanModal(true)
                }
            }
        }).catch((err) => {
            console.log("my details error: ", err);
        })
    }

    return (
        <>
            <section className={scroll ? "header scrolled" : "header"}>
                <Container>
                    <div className="header-inner">
                        <div className="header-right">
                            <div className="sidebar-head">
                                <Link to="/?chatType=chat"><h2>Helpr</h2></Link>
                            </div>
                            {location.pathname === "/" &&
                                <>
                                    {ADVANCE_FEATURES.map((data, index) =>
                                        <div className={`head-right-inner${queryParams.get('chatType') === data.type ? ' active' : ''}`} key={index} onClick={() => { if (!globalContext.generating) {
                                            changeChatModel(data.route)
                                        }}}>
                                            <img src={data.image} alt="img" />
                                            <div className="header-right-left-area">
                                                <h6>{data.name}</h6>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                        </div>
                        <div className="header-left">
                            {globalContext.loggedIn ?
                                <>
                                    <a id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setOpen(true)}>
                                        <img src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image :  require("../assets/images/profile.png")} alt="img" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a><img src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image :  require("../assets/images/profile.png")} alt="img" /> {globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email}{!globalContext.userInfo?.subscription_id && <span>Free</span>}</a></li>
                                        <li onClick={() => navigate("/account-setting")}><a>My Account</a></li>
                                        {!globalContext.userInfo?.subscription_id && <li onClick={() => upgradePlan()}><a>Upgrade to Pro</a></li>}
                                        <li onClick={() => logout()}><a>Logout</a></li>
                                    </ul>
                                </>
                                :
                                <Button type="button" variant="unset" onClick={() => setShowLogin(true)}>Login</Button>
                            }
                        </div>
                    </div>
                </Container>
            </section>

            {/* login modal */}
            <Modal show={showLogin} fullscreen={true} onHide={() => {setShowLogin(false); navigate("/", { replace: true })}} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Sign In</h2>
                                    <p>Use registered email address and password.</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit(onSubmitLogin)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register("email", {
                                                required: "Email is required.", pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: "Invalid email address."
                                                }
                                            })} />
                                            {errors.email && <span className="err-msg">{errors.email.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Enter Password</Form.Label>
                                            <div className="password-area">
                                                <div className="password-area-inner">
                                                    <Form.Control type={passType} placeholder="Password" {...register("password", { required: "Password is required" })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                    <i onClick={() => setPassType(passType === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType === "password" ? "-slash" : ""}`} />
                                                </div>
                                                {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                            </div>
                                            <p className="forgot-text">
                                                <a onClick={() => setShowForgot(true)} >Forgot Password?</a>
                                            </p>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Sign In
                                        </Button>
                                        {/* <p>Don’t have an account?<a onClick={() => handleShowSignup()}>Sign Up</a></p> */}
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            {/* signup modal */}

            <Modal show={showSignup} fullscreen={true} onHide={() => setShowSignup(false)} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Sign Up</h2>
                                    <p>Get started with Helpr.</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit2(onSubmitSignup)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register2("email", {
                                                required: "Email is required", pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: "Invalid email address."
                                                }
                                            })} />
                                            {errors2.email && <span className="err-msg">{errors2.email.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Enter Password</Form.Label>
                                            <div className="password-area">
                                                <div className="password-area-inner">
                                                    <Form.Control type={passType} placeholder="Password" {...register2("password", { required: "Password is required.", pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                        message: "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
                                                    } })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                                    <i onClick={() => setPassType(passType === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType === "password" ? "-slash" : ""}`} />
                                                </div>
                                                {errors2.password && <span className="err-msg">{errors2.password.message}</span>}
                                            </div>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Sign Up
                                        </Button>
                                        <p>Already have an account?<a onClick={() => setShowSignup(false)}>Sign In</a></p>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            {/* forgot modal */}
            <Modal show={showForgot} fullscreen={true} onHide={() => setShowForgot(false)} className="subscription-popup forgot-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                    <h2>Forgot Password?</h2>
                                    <p>Enter the email address associated with your account and we’ll send you a link to reset your password.</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit1(onSubmitForgot)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" {...register1("email", {
                                                required: "Email is required.", pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: "Invalid email address."
                                                }
                                            })} />
                                            {errors1.email && <span className="err-msg">{errors1.email.message}</span>}
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Continue
                                        </Button>
                                        <p><img src={require("../assets/images/back.png")} alt="img" />Back to <span onClick={() => setShowForgot(false)}>Sign In</span></p>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
                    
            {/* Plans modal */}
            <Modal show={globalContext.planModal} fullscreen={true} onHide={() => globalContext.setPlanModal(false)} className='subscribe-modal'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={6} xl={6} xxl={7} className="col-12 p-0">
                            <div className="subscription-content-inner">
                                <h2>Helpr<img src={require("../assets/images/blue-star.png")} alt="img" /></h2>
                                <h4>Upgrade to Helpr App Pro</h4>
                                <p>Unlock the full potential of Helpr App</p>
                                <div className="subscription-content-body">
                                    {globalContext?.planList?.map((d, i) => 
                                        <div key={i}>
                                        {d.trial_period != null && <div className='subscription-vector-outer'>
                                            <div className='subscription-vector'>
                                                <img src={require("../assets/images/subscription-vector.png")} alt='img' />
                                            </div>
                                            <p>7 Days Free Trial Period</p>
                                        </div>}
                                        <div className="setting-pricing-area">
                                            <div className={`setting-pricing-head-area${d.trial_period == null ? " no-plan-head": ""}`}>
                                                <div className="setting-pricing-head-left">
                                                    <h6>{d?.billing_cycle?.frequency} {d?.billing_cycle?.interval}</h6>
                                                    <p>${(d?.unit_price?.amount)/100}</p>
                                                </div>
                                                <div className="setting-pricing-head-left per-day-cost">
                                                <h3><span>$0.66 Per Day </span></h3>
                                                </div>
                                            </div>
                                            <div className="setting-pricing-body">
                                                <ul>
                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Unlimited access to text chat</li>
                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Generate unlimited images</li>
                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Upload PDFs and ask unlimited questions</li>
                                                    <li><img src={require("../assets/images/check.png")} alt="img" />Add links and ask unlimited questions</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>)}

                                    <Button type="buton" variant="unset" onClick={() => buyPlan()}>Continue</Button>

                                </div>

                                <div className="subscription-info">
                                    <p>By completing your purchase, you agree to our <span>Terms of Service, Privacy Policy,</span> and <span>Refund & Cancellation Policy.</span></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Header;