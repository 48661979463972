import { Routes, Route } from "react-router-dom";
import Chat from "../pages/Chat";
import Paddle from "../pages/Paddle";
import AccountSetting from "../pages/AccountSetting";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import RestPassword from "../pages/ResetPassword";
import PrivacyPolicy from "../common/PrivacyPolicy";
import TermsConditions from "../common/TermsConditions";
import NotFound from "../common/NotFound";
import { LandingPage } from "../pages/LandingPage";
import { SetPassword } from "../pages/SetPassword";

const Routing = () => {

    const globalContext = useContext(GlobalContext);

    return (
        <Routes>
            <Route
                path="/checkout"
                element={<Paddle />}>
            </Route>
            <Route
                path="/"
                element={<Chat />}>
            </Route>
            {globalContext.loggedIn && <>
                <Route
                    path="/account-setting"
                    element={<AccountSetting />}>
                </Route>
                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}>
                </Route>
                <Route
                    path="/term-condition"
                    element={<TermsConditions />}>
                </Route>
            </>}
            {!globalContext.loggedIn &&
                <>
                    <Route
                        path="/reset-password/:token"
                        element={<RestPassword />}>
                    </Route>
                    <Route
                        path="/get-help"
                        element={<LandingPage />}>
                    </Route>
                    <Route
                        path="/set-password/:transaction"
                        element={<SetPassword />}>
                    </Route>
                    <Route
                        path="/help-privacy-policy"
                        element={<PrivacyPolicy />}>
                    </Route>
                    <Route
                        path="/help-term-condition"
                        element={<TermsConditions />}>
                    </Route>
                </>
            }
            
            <Route
                path="*"
                element={<NotFound />}>
            </Route>
        </Routes>
    );
};
export default Routing;
