import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const TermsConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="common-top-spacing">
                <section className="privacy-policy-outer">
                    <Container>
                        <div className="privacy-policy-inner">
                            <h1>Terms & Conditions</h1>
                            <h5>Introduction</h5>
                            <p>Welcome to ChatGPT, an AI-powered conversational platform developed by OpenAI. These Terms and Conditions ("Terms") govern your access to and use of ChatGPT services ("Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>
                            <h5>Use of Services </h5>
                            <h6>1. Eligibility:</h6>
                            <ul>
                                <li>You must be at least 13 years old to use ChatGPT. By using our Services, you represent and warrant that you meet this age requirement.</li>
                            </ul>
                            <h6>2. Account Registration:</h6>
                            <ul>
                                <li>You may be required to create an account to access certain features of the Services. You agree to provide accurate and complete information when creating your account and to keep this information up-to-date.
                                </li>
                            </ul>
                            <h6>3. User Responsibilities:</h6>
                            <ul>
                                <li>You are responsible for all activities that occur under your account. You agree to keep your login credentials confidential and to notify us immediately of any unauthorized use of your account.
                                </li>
                                <li>You agree not to use the Services for any illegal or unauthorized purpose, and you agree to comply with all applicable laws and regulations.</li>
                            </ul>
                            <h6>4. Prohibited Conduct:</h6>
                            <ul>
                                <li>Use the Services to transmit any harmful, offensive, or illegal content.
                                </li>
                                <li>Engage in any activity that interferes with or disrupts the Services.</li>
                                <li>Attempt to gain unauthorized access to the Services or any related systems or networks.</li>
                                <li>Reverse engineer, decompile, or disassemble any aspect of the Services.</li>
                            </ul>

                            <h5>Intellectual Property</h5>
                            <h6>1. Ownership:</h6>
                            <ul>
                                <li>All content, trademarks, and intellectual property associated with ChatGPT are the property of OpenAI or its licensors. You do not acquire any ownership rights by using our Services.</li>
                            </ul>
                            <h6>2. License:</h6>
                            <ul>
                                <li>Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use the Services for your personal, non-commercial use.
                                </li>
                            </ul>
                        
                            <h5>Content</h5>
                            <h6>1. User Content:</h6>
                            <ul>
                                <li>You retain ownership of any content you submit through the Services. By submitting content, you grant OpenAI a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content for the purpose of providing and improving the Services.</li>
                            </ul>
                            <h6>2. Content Moderation:</h6>
                            <ul>
                                <li>We reserve the right to monitor and review user content and to remove or disable access to any content that violates these Terms or is otherwise objectionable.</li>
                            </ul>
                        
                            <h6>Privacy</h6>
                            <p>Your use of the Services is also governed by our Privacy Policy, which explains how we collect, use, and protect your information. By using the Services, you consent to our collection and use of your information as described in the Privacy Policy.</p>
                    
                            <h6>Indemnification</h6>
                            <p>You agree to indemnify, defend, and hold harmless OpenAI, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.</p>
                            <h6>Termination</h6>
                            <p>We reserve the right to suspend or terminate your access to the Services at any time, with or without cause or notice, for any reason, including if we believe you have violated these Terms.</p>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
}
export default TermsConditions;